@font-face {
  font-family: 'ITC Avant Garde Gothic Std Bold';
  src: local('ITC Avant Garde Gothic Std Bold'),
    url('./fonts/Itc/ITCAvantGardeStdBold.woff') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'ITC Avant Garde Gothic Std Medium';
  src: local('ITC Avant Garde Gothic Std Medium'),
    url('./fonts/Itc/ITCAvantGardeStdMd.woff') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'ITC Avant Garde Gothic Std Medium';
  src: local('ITC Avant Garde Gothic Std Medium'),
    url('./fonts/Itc/ITCAvantGardeStdMd.woff') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'ITC Avant Garde Gothic Std Demi';
  src: local('ITC Avant Garde Gothic Std Demi'),
    url('./fonts/Itc/ITCAvantGardeStdDemi.woff') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'ITC Avant Garde Gothic Std Normal';
  src: local('ITC Avant Garde Gothic Std Normal'),
    url('./fonts/Itc/ITCAvantGardeStdBk.woff') format('truetype');
  font-weight: bold;
}
@font-face {
  font-family: 'ITC Avant Garde Gothic Std Thin';
  src: local('ITC Avant Garde Gothic Std Thin'),
    url('./fonts/Itc/ITCAvantGardeStdXLt.woff') format('truetype');
  font-weight: bold;
}

body {
  margin: 0;
  font-family: ITC Avant Garde Gothic Std Bold, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p,
ul > li,
span {
  font-family: ITC Avant Garde Gothic Std Medium;
}

code {
  font-family: ITC Avant Garde Gothic Std Bold, monospace;
}

strong {
  font-weight: bolder;
  font-family: 'ITC Avant Garde Gothic Std Bold';
}
.weglot-container {
  display: none;
}
.MuiAlert-filledSuccess {
  background-color: green !important;
}
